import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DragHandleHorizontalIcon, PersonIcon } from '@radix-ui/react-icons';
import { Avatar } from '@producer-io/ui-kit';
import styled from 'styled-components';

import { TaskCollection } from './task-collection';
import { Task, TaskLevel, TaskStatus } from '../../../app/entities/task';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { ToolBar } from '../../../components/toolbar/toolbar';
import { ProjectPriority, ProjectPriorityByValue } from '../../../app/entities/project';
import { PriorityIcon } from '../../process/components/priority-selector';
import { StatusSelector } from '../../process/components/status-selector';
import { ElevatedButton } from '../../../components/buttons';
import { Member } from '../../../app/entities/member';
import { CustomAvatar } from './styled-tasks-components';
import { taskGroupingStore } from '../../../core/stores/task-store';
import { SpaceTasksFilters } from './space-tasks-filters/space-tasks-filters';
import { PagePlaceholder } from '../../../components/page-placeholder/page-placeholder';
import { ProjectTasksFilters } from './project-tasks-filters/project-tasks-filters';

type TaskListProps = {
  tasks: Task[];
  taskLevel: TaskLevel;
  onClearFilters?: () => void;
};

type Group = {
  name: string;
  value: any;
  tasks: Task[];
};

type SortedGroups = {
  [key: string]: Group;
};

const options = [
  {
    key: 'status',
    defaultValue: TaskStatus.Todo,
    label: 'Status',
    getTitle: (task: Task) => Task.StatusMapper[task.status].title,
    getDefaultValue: (status: string) => ({ status }),
    getIcon: (value: TaskStatus) => (
      <StatusSelector status={value} disableTooltip isViewOnly statusMap={Task.StatusMapper} />
    ),
    sortBy: (a: Group, b: Group) => {
      if (!a.value) return -1;
      if (!b.value) return 1;
      return a.value > b.value ? -1 : 1;
    },
  },
  {
    key: 'assigneeId',
    label: 'Assignee',
    defaultValue: '',
    getDefaultValue: (assigneeId: string) => ({ assigneeId }),
    getTitle: (task: Task) => task.assignee?.fullName ?? 'No assignee',
    getIcon: (assigneeId: string) => {
      const assignee = Member.getOne(assigneeId)! as Member;

      if (!assignee) {
        return (
          <AvatarWrapper>
            <CustomAvatar>
              <PersonIcon />
            </CustomAvatar>
          </AvatarWrapper>
        );
      }

      return (
        <PrioritySelectorWrapper>
          <Avatar size="small" theme="dark" initials={assignee.initials} src={assignee.avatar} />
        </PrioritySelectorWrapper>
      );
    },
    sortBy: (a: Group, b: Group) => {
      if (!a.value) return -1;
      if (!b.value) return 1;
      return a.value > b.value ? -1 : 1;
    },
  },
  {
    key: 'priority',
    label: 'Priority',
    defaultValue: ProjectPriority.NO_PRIORITY,
    getDefaultValue: (priority: string) => ({ priority }),
    getTitle: (task: Task) =>
      task.priority ? ProjectPriorityByValue[task.priority] : 'No priority',
    getIcon: (value: ProjectPriority) => (
      <PrioritySelectorWrapper>
        <PriorityIcon priority={value} />
      </PrioritySelectorWrapper>
    ),
    sortBy: (a: Group, b: Group) => {
      if (!a.value) return -1;
      if (!b.value) return 1;
      return a.value > b.value ? -1 : 1;
    },
  },
];

export const TaskList: React.FC<TaskListProps> = observer(
  ({ tasks, taskLevel, onClearFilters }) => {
    const [groups, setGroups] = useState<SortedGroups>();
    const groupBy = options.find((el) => el.key === taskGroupingStore.groupBy)!;

    useEffect(() => {
      if (!tasks) return;

      const groups: SortedGroups = {};
      tasks.forEach((task) => {
        const groupByKey = (task[groupBy.key as keyof Task] ||
          groupBy.defaultValue) as keyof typeof groups;

        if (!groups[groupByKey]) {
          groups[groupByKey] = {
            name: groupBy.getTitle(task),
            value: groupByKey ?? groupBy.defaultValue,
            tasks: [],
          };
        }

        groups[groupByKey].tasks.push(task);
      });

      setGroups(groups);
    }, [tasks, groupBy]);

    const items = options.map((option) => ({
      title: option.label,
      onCheckedChange: () => {
        if (taskGroupingStore.groupBy !== option.key) {
          taskGroupingStore.setGroupBy(option.key);
        }
      },
      isChecked: groupBy.key === option.key,
    }));

    return (
      <>
        <ToolBar>
          {taskLevel === 'space' ? <SpaceTasksFilters /> : <ProjectTasksFilters />}

          <div />

          <DropdownMenu
            items={items}
            trigger={
              <div>
                <GroupingTrigger
                  variant="default"
                  text="Grouping"
                  icon={<DragHandleHorizontalIcon />}
                />
              </div>
            }
          />
        </ToolBar>

        <Container>
          {tasks?.length ? (
            groups &&
            Object.values<Group>(groups)
              .sort(groupBy.sortBy)
              .map((group: any) =>
                group.tasks.length ? (
                  <TaskCollection
                    key={`${group.value}-${group.name}-${group.tasks.length}`}
                    title={group.name}
                    value={group.value}
                    tasks={group.tasks}
                    getIcon={groupBy.getIcon}
                    defaultValue={groupBy.getDefaultValue(group.value)}
                    taskLevel={taskLevel}
                  />
                ) : null,
              )
          ) : (
            <PagePlaceholder
              title="No tasks matching the filters"
              actions={[
                <ElevatedButton
                  key="clear-filters"
                  text="Clear filters"
                  onClick={onClearFilters}
                />,
              ]}
              backgroundImgUrl="/images/order-from-chaos.svg"
              fullWidth
            />
          )}
        </Container>
      </>
    );
  },
);

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  overflow-x: clip;
`;

const PrioritySelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  width: 2.4rem;
  height: 2.4rem;
  margin-inline-end: 0.8rem;
  background-color: #25272d;
`;

const GroupingTrigger = styled(ElevatedButton)`
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0 0.8rem;
  gap: 0.4rem;
  background: var(--color-surfaces-bg-elevation-1);
  color: var(--color-texts-high-contrast);

  svg {
    height: 1.6rem;
    width: 1.6rem;
  }
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 0.8rem;
`;
