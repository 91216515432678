import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TrashIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { DropdownTrigger } from '../../projects/components/styled-project-components';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { InlineEditableText } from '../../../components/editable-text/inline-editable-text';
import { Left, Strip, TitleWrapper, Wrapper } from './styled-tags-components';
import { ColorPicker } from './color-picker.component';
import { Tag } from '../../../app/entities/tag';

type TagItemProps = {
  tag: Tag;
};

export const TagItem: React.FC<TagItemProps> = observer(({ tag }) => {
  const { t } = useTranslation('video');
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const menuItems: React.ComponentProps<typeof DropdownMenu>['items'] = [
    {
      title: 'Delete tag',
      icon: <TrashIcon />,
      type: 'danger' as const,
      onSelect: () => setDeleteModalIsOpen(true),
    },
  ];

  const titleValidatorSchema = yup
    .string()
    .required(t('common:fieldRequired'))
    .max(24, t('common:fieldMax', { max: 24 }));

  const getUsagePlaceholder = () => {
    const usage = tag.usage;
    const target = tag.target;

    if (!usage) return 'Not used';
    return usage > 1 ? `${usage} ${target}s` : `${usage} ${target}`;
  };

  return (
    <Strip>
      <Wrapper data-full-width="true">
        <Left>
          <ColorPicker colorHex={tag.color} onChange={(color) => tag.update({ color })} />
        </Left>

        <TitleWrapper>
          <InlineEditableText
            validationType="onChange"
            schema={titleValidatorSchema}
            value={tag.text}
            onSubmit={(text) => tag.update({ text })}
          />
        </TitleWrapper>
      </Wrapper>

      <Right>
        <div>{getUsagePlaceholder()}</div>
        <DropdownMenu
          items={menuItems}
          trigger={<DropdownTrigger data-size="small">⋮</DropdownTrigger>}
        />
      </Right>

      {deleteModalIsOpen && (
        <DeleteDialog
          title="Delete tag"
          text={`The tag and its references will be deleted completely from the space.`}
          onCancel={() => setDeleteModalIsOpen(false)}
          onSubmit={() => tag.delete()}
        />
      )}
    </Strip>
  );
});

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 3.2rem;
  min-width: 12rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-grayscale-white);

  & > div {
    text-transform: capitalize;
  }
`;
