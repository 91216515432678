import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Pencil1Icon, UpdateIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';
import { EditorContent } from '@tiptap/react';

import Sunset from '../../../components/icons/weather-icons/Sunset';
import Sunrise from '../../../components/icons/weather-icons/Sunrise';
import CloudAndWindy from '../../../components/icons/weather-icons/CloudAndWindy';
import { InfoCard } from './InfoCard';
import { WeatherModal } from './weather.modal';
import { IconButton } from '../../../components/buttons';
import { IconButtonWrapper } from '../../../components/buttons/icon-button';
import { breakPoint } from '../../../app/theme';
import { useResponsive } from 'hooks/useResponsive';

import type { Step } from '../../../app/entities/step';
import { EditorBubbleMenu } from '../../../components/rich-editor/editor-bubble-menu';
import { useRichTextEditor } from '../../../hooks/useRichTextEditor';

type WeatherWidgetProps = {
  step: Step;
};

export const WeatherWidget: React.FC<WeatherWidgetProps> = observer(({ step }) => {
  const [isWeatherModalOpen, setIsWeatherModalOpen] = useState(false);
  const { isDesktop } = useResponsive();

  const { editor, isTextSelected } = useRichTextEditor({
    key: step._id,
    value: step.info?.generalNote || '',
    onUpdate: (text) => {
      step.update({ info: { generalNote: text } });
    },
  });

  const handleUpdateWeather = async () => {
    await step.updateWeatherDetails();
  };

  return (
    <>
      <WidgetContainer>
        <WeatherContainer>
          <SunDataWrapper style={{ fontWeight: '400' }}>
            <CloudAndWindy />

            {step.info?.weather?.description || '-'}
          </SunDataWrapper>

          <Temperature>
            {step.info?.weather?.temp?.min || '-'}/{step.info?.weather?.temp?.max || '-'}
          </Temperature>

          <WeatherWrapper>
            <SunDataWrapper>
              <Sunrise />

              <span>
                Sunrise:&nbsp;<SunTime>{step.info?.weather?.sunrise || '-'}</SunTime>
              </span>
            </SunDataWrapper>

            <SunDataWrapper>
              <Sunset />

              <span>
                Sunset:&nbsp;<SunTime>{step.info?.weather?.sunset || '-'}</SunTime>
              </span>
            </SunDataWrapper>
          </WeatherWrapper>
        </WeatherContainer>

        <ModifiedButtonWrapper>
          <IconButton
            variant="dark"
            icon={<UpdateIcon />}
            onClick={handleUpdateWeather}
            size={isDesktop ? 'medium' : 'small'}
          />

          <IconButton
            variant="dark"
            icon={<Pencil1Icon />}
            onClick={() => setIsWeatherModalOpen(true)}
            size={isDesktop ? 'medium' : 'small'}
          />
        </ModifiedButtonWrapper>
      </WidgetContainer>

      <InfoCard
        step={step}
        projectId={step.projectId}
        startDate={step.startDate}
        dueDate={step.dueDate}
        generalCall={step.info?.generalCallTime || { hour: 0, minute: 0 }}
      />

      <NoteWrapper>
        <NoteHeader>General note</NoteHeader>

        {editor && isTextSelected && <EditorBubbleMenu editor={editor} />}
        <NotesInput editor={editor} placeholder="Add a general note" rows={4} cols={30} />
      </NoteWrapper>

      {isWeatherModalOpen && (
        <WeatherModal
          onClose={() => setIsWeatherModalOpen(false)}
          step={step}
          defaultValues={step.info?.weather}
        />
      )}
    </>
  );
});

const WidgetContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 0.8rem 1.6rem;
  border-radius: 0.4rem;
  background: var(--color-grayscale-tuna);
  order: 2;
  min-width: 0;

  @media screen and (min-width: ${breakPoint.medium}px) {
    border-radius: 0.4rem 0.4rem 0 0.4rem;
    padding: 2.4rem;
    order: unset;
  }
`;

const WeatherContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1.6rem;
  align-items: center;
  width: 100%;
`;

const Temperature = styled.div`
  font-weight: 700;
  font-size: 2.4rem;
  color: var(--color-grayscale-athens);

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 4rem;
  }
`;

const WeatherWrapper = styled.div`
  display: flex;
  gap: 1.2rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    gap: 3.2rem;
  }
`;

const NoteWrapper = styled.div`
  width: 100%;
  padding: 0.8rem 1.6rem;
  background: var(--color-grayscale-tuna);
  border-radius: 0.4rem;
  order: 3;
  min-width: 0;

  @media screen and (min-width: ${breakPoint.medium}px) {
    border-radius: 0.4rem 0.4rem 0.4rem 0;
    order: unset;
    padding: 2.4rem;
  }
`;

const NoteHeader = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--color-grayscale-white);
  margin-bottom: 0.8rem;
`;

const SunDataWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--color-grayscale-cadet);
  text-transform: capitalize;

  @media screen and (max-width: ${breakPoint.medium + 1}px) {
    & > svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 1.6rem;
  }
`;

const SunTime = styled.span`
  color: var(--color-grayscale-white);
`;

const NotesInput = styled(EditorContent)`
  border-radius: 0.4rem;
  border: 0.1rem solid var(--color-grayscale-trout);
  padding: 1.6rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--color-grayscale-white);
  height: 10.6rem;

  position: relative;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
  letter-spacing: 0.3px;
  resize: none;
  background-color: transparent;

  &::placeholder {
    color: var(--color-grayscale-light-slate);
  }

  &:hover {
    background-color: var(--color-grayscale-arsenic);
    cursor: text;
  }

  &:focus {
    background-color: transparent !important;
    border-color: var(--color-grayscale-trout);
  }

  &[data-error='true'] {
    &:focus {
      border-color: var(--color-error);
    }
  }
`;

const ModifiedButtonWrapper = styled(IconButtonWrapper)`
  display: flex;
  gap: 1rem;

  @media screen and (max-width: ${breakPoint.medium + 1}px) {
    position: absolute;
    top: 0.8rem;
    right: 1.6rem;
  }
`;
