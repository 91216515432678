import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ClipboardIcon } from '@radix-ui/react-icons';

import { Date } from '../date/date';
import { DateSelectionModal } from '../../features/process/components/date-selection.modal';
import { Step, StepStatus } from '../../app/entities/step';
import { DeleteDialog } from '../dialogs/DeleteDialog';
import { AssigneeSelector } from '../assignee-selector/assignee-selector';
import { StatusSelector } from '../../features/process/components/status-selector';
import { useCurrentMember } from '../../hooks/use-current-member';
import { KanbanCardAction } from '../../features/kanban/components/kanban-card-action';
import { uiStore } from '../../core/stores/ui-store';

type StepItemProps = {
  step: Step;
};

export const StepItem: React.FC<StepItemProps> = observer(({ step }) => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const [isEditOpen, setEditOpen] = useState(false);

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: step._id,
    data: {
      type: 'step',
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const currentMember = useCurrentMember();

  const isShootingDay = step.type === 'shootingDay';

  const handleDelete = async () => {
    setDeleteModalIsOpen(false);
  };

  const handleOpenDate = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setEditOpen(true);
  };

  const handleShowTasksPanel = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (!uiStore.isTaskPanelOpen) {
      uiStore.openTaskPanel(step._id);
    } else if (uiStore.taskPanelTargetId === step._id) {
      uiStore.closeTaskPanel();
    } else {
      uiStore.openTaskPanel(step._id);
    }
  };

  const handleUpdateStep = async (date: any) => {
    step.update({ ...(isShootingDay ? { startDate: date, dueDate: date } : { dueDate: date }) });
  };

  const handleChangeStatus = async (newStatus: StepStatus) => {
    try {
      await step.update({ status: newStatus });
    } catch (e) {
      console.error(e);
    }
  };

  const goTo = () => navigate(`./steps/${step._id}`);

  return (
    <>
      <TaskItem
        data-status={step.status}
        id={`${step.displayName.toLowerCase().replaceAll(' ', '-')}-step`}
        onClick={goTo}
        data-is-done={step.done}
        ref={!isShootingDay ? setNodeRef : undefined}
        style={!isShootingDay ? style : undefined}
        data-dragging={isDragging}
        {...(!isShootingDay ? attributes : {})}
        {...(!isShootingDay ? listeners : {})}
      >
        <StatusSelector
          onChange={handleChangeStatus}
          statusMap={Step.StatusMapper}
          status={step.status}
          isViewOnly={currentMember?.role === 'guest'}
        />

        <div style={{ flex: 1 }}>
          <Header className="row">
            <Title>{step.displayName}</Title>

            <div onClick={(e) => e.stopPropagation()}>
              <AssigneeSelector
                isViewOnly={currentMember?.role === 'guest'}
                project={step.project!}
                selectedMemberId={step.assigneeId}
                onChange={(assigneeId) => step.update({ assigneeId })}
                iconOnly
              />
            </div>
          </Header>

          <Footer className="row">
            <Date date={isShootingDay ? step.startDate : step.dueDate} onClick={handleOpenDate} />
            <KanbanCardAction
              icon={<ClipboardIcon />}
              onClick={handleShowTasksPanel}
              text={
                step.activeTasksCount ? `${step.completedTask}/${step.activeTasksCount}` : undefined
              }
            />
          </Footer>
        </div>
      </TaskItem>

      {deleteModalIsOpen && (
        <DeleteDialog
          title={'Delete step ?'}
          text={`Are you sure you want to delete ${step.name}?`}
          onCancel={() => setDeleteModalIsOpen(false)}
          onSubmit={handleDelete}
        />
      )}

      {isEditOpen && (
        <DateSelectionModal
          onCancel={() => setEditOpen(false)}
          fieldName={isShootingDay ? 'startDate' : 'dueDate'}
          value={isShootingDay ? step.startDate : step.dueDate}
          onSubmit={handleUpdateStep}
        />
      )}
    </>
  );
});

const Header = styled.div`
  align-items: center;
  justify-content: space-between;
`;

const Footer = styled.div`
  gap: 0.4rem;
`;

const TaskItem = styled.div`
  width: 100%;
  font-weight: 600;
  border-radius: var(--card-border-radius);
  background-color: var(--bg-elevation-2);
  display: flex;
  padding: 0.8rem;
  position: relative;
  cursor: pointer;
  color: var(--step-title-color);

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  &[data-dragging='true'] {
    opacity: 0.5;
  }

  &[data-is-done='true'] {
    color: #8a8f98;
    background-color: rgba(49, 50, 54, 0.5);
  }
`;

const Title = styled.div`
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.6rem;
  margin-bottom: 1.2rem;
  margin-top: 0.4rem;
  min-width: 0;
  max-width: 87%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
`;
