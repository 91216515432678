import axios from 'axios';

import { api } from '../../../api';
import { Space } from '../../../app/entities/space';
import { entityPool } from '../../../core/engine/engine';
import { Contact } from '../../../app/entities/contact';
import { Project } from '../../../app/entities/project';
import { Stage } from '../../../app/entities/stage';
import { Step } from '../../../app/entities/step';
import { Member } from '../../../app/entities/member';
import { Tag } from '../../../app/entities/tag';
import { Collaborator } from '../../../app/entities/collaborator';

/**
 * Fetch user spaces
 */
type FetchSpacesInput = {
  userId: string;
};

export const fetchSpaces = async (input: FetchSpacesInput) => {
  const { data } = await api.get(`/users/${input.userId}/spaces`);

  if (data && data.length > 0) {
    const currentUrlSplit = window.location.pathname.split('/');
    if (currentUrlSplit.length > 2) {
      const spaceId = currentUrlSplit[2];
      api.updateSpaceInHeaders(spaceId);
    } else {
      api.updateSpaceInHeaders(data[0]._id); // having this update here insures that when the web app first loads there will not be any request without X-spaceId, other than /me and /spaces
    }
    data.forEach((space: Space) => {
      entityPool.insert(Object.assign(new Space(), space));
    });
  }

  return data;
};

/**
 * Create a space
 */

type CreateSpacesInput = {
  name: string;
  referrer?: string;
};

export const createSpace = async (input: CreateSpacesInput) => {
  const { data } = await api.post('/spaces', input);
  api.updateSpaceInHeaders(data._id);
  entityPool.insert(Object.assign(new Space(), data));
  return data;
};

type UpdateSpaceInput = {
  space: Space;
  values: Partial<Space>;
};

export const updateSpace = async (input: UpdateSpaceInput) => {
  const { values, space } = input;
  const { data } = await api.patch(`/spaces/${space._id}`, values);

  entityPool.updateEntity<Space>(space._id, data);

  return data;
};

type UploadPictureType = 'logo' | 'avatar';

type UpdateProjectCoverInput = {
  space: Space;
  file: File;
  type: UploadPictureType;
};

export const updateSpacePicture = async ({ space, file, type }: UpdateProjectCoverInput) => {
  const { data } = await api.put(`/spaces/${space._id}/pictures/${type}`, {
    fileSize: file.size,
    fileType: file.type,
  });

  await axios.put(data.links.upload, file, {
    headers: {
      'Content-Type': file.type,
    },
  });

  // Trick the browser to reload the pictures as they always keep the same name
  if (data.avatar) {
    data.avatar = `${data.avatar}?ts=${Date.now()}`;
  }

  if (data.logo) {
    data.logo = `${data.logo}?ts=${Date.now()}`;
  }

  entityPool.updateEntity<Space>(space._id, data);

  return data;
};

export const bootstrapSpace = async (spaceId: string) => {
  const { projects, contacts, stages, steps, members, tags } = await api
    .get(`/spaces/${spaceId}/bootstrap`)
    .then((response) => response.data);

  members.forEach((el: Member) => {
    const member = Object.assign(new Member(), el);
    entityPool.insert(member);
  });

  projects.forEach((el: any) => {
    /** Backend is sending a shootingDays value that is not use **/
    const { shootingDays, ...values } = el;

    const project = Object.assign(new Project(), values);
    entityPool.insert(project);
  });

  contacts.forEach((el: any) => {
    const contact = Object.assign(new Contact(), el);
    entityPool.insert(contact);
  });

  insertStagesAndSteps(stages, steps);

  tags.forEach((tag: Tag) => {
    entityPool.insert(Object.assign(new Tag(), tag));
  });

  fetchSpaceCollaborators(spaceId);
};

export const fetchCalendarData = async (spaceId: string) => {
  const {
    data: { steps, stages },
  } = await api.get(`/spaces/${spaceId}/stepsAndStages`);

  insertStagesAndSteps(stages, steps);
};

const insertStagesAndSteps = (stages: Stage[], steps: Step[]) => {
  for (const stage of stages) {
    const stageEntity = Object.assign(new Stage(), stage);
    entityPool.insert(stageEntity);
  }

  // handle steps
  if (steps && steps.length > 0) {
    for (const step of steps) {
      const stepEntity = Object.assign(new Step(), step, { assets: [] });
      entityPool.insert(stepEntity);
    }

    // at this point the steps are already in the store and attached to the stages and ready for sorting
    const stages = Stage.getAll();
    for (let i = 0; i < stages.length; i++) {
      stages[i].steps.sort((a, b) => a.order - b.order);
    }
  }
};

const fetchSpaceCollaborators = async (spaceId: string) => {
  const { data: collaborators } = await api.get(`/spaces/${spaceId}/collaborators`);
  collaborators.forEach((collaborator: Collaborator) => {
    entityPool.insert(Object.assign(new Collaborator(), collaborator));
  });
};
