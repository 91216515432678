import { Model } from '../../core/engine/model';
import { ManyToOne } from '../../core/engine/decorators';
import { Space } from './space';
import { api } from '../../api';
import { getFullName } from '../../lib/utils/get-full-name';
import { getNameInitials } from '../../lib/utils/get-name-initials';

export class Invitation extends Model {
  spaceId: string;
  displayName: string;
  userId: string;
  role: string;
  email: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  status?: string;
  invitedAt?: string;

  @ManyToOne('invitations')
  space: Space;

  get fullName() {
    return getFullName(this) || this.email;
  }

  get initials() {
    return getNameInitials(this) || this.email[0];
  }

  constructor() {
    super('invitations');
  }

  async resend() {
    await api.put(`/invitations/${this._id}/resend`);
    const invitedAtDate = new Date();
    this.invitedAt = invitedAtDate.toISOString();
  }

  toPOJO(): Record<string, any> {
    return {};
  }
}
