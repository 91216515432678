import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DownloadIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';

import { useStoryboard } from '../hooks/useStoryboard';
import { ScreenHeader } from '../../projects/components/screen-header';
import { BufferToBlob } from '../../../lib/utils/BufferToBolb';
import { createFileName } from '../../../core/services/file.service';
import { RenameStepModal } from '../../process/components/rename-step.modal';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { useResponsive } from '../../../hooks/useResponsive';

import type { Step } from '../../../app/entities/step';
import type { Project } from '../../../app/entities/project';
import { LimitReachedModal } from '../../../components/dialogs/limit-reached.modal';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { Storyboard } from '../../../app/entities/storyboard';
import { entityPool } from '../../../core/engine/engine';
import { useCurrentMember } from '../../../hooks/use-current-member';

type StoryboardHeaderProps = {
  step: Step;
  project: Project;
};

export const StoryboardHeader: React.FC<StoryboardHeaderProps> = observer(({ step, project }) => {
  const { isDesktop } = useResponsive();

  const navigate = useNavigate();

  const currentMember = useCurrentMember();

  const storyboardPreferences = currentMember?.preferences?.storyBoardPreferences;

  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [showLimitReachedModal, setShowLimitReachedModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const storyboard = project.storyboard;

  const { fetchStoryboardAsPDF } = useStoryboard(project._id);

  const handleDownload = async () => {
    if (generatingPDF || !project || !storyboard) {
      return;
    }

    if (!storyboard.project?.space?.canExportPdf()) {
      return setShowLimitReachedModal(true);
    }

    setGeneratingPDF(true);

    try {
      const file = await fetchStoryboardAsPDF(
        storyboard._id,
        storyboardPreferences?.displayedProperties,
      );

      BufferToBlob(file, createFileName(project.name, `${storyboard.name || 'Storyboard'}.pdf`));
    } catch (e) {
      console.log(e);
    }

    setGeneratingPDF(false);
  };

  const confirmDeleteStep = () => {
    if (step.storyboardId) {
      const storyboard = Storyboard.getOne(step.storyboardId);
      if (storyboard) entityPool.delete(storyboard);
    }
    navigate('../');
    step.delete();
  };

  const items: React.ComponentProps<typeof DropdownMenu>['items'] = [
    ...(!isDesktop
      ? [
          {
            title: 'Generate PDF',
            icon: <DownloadIcon />,
            onSelect: handleDownload,
            disabled: generatingPDF || !storyboard?.shots?.length,
          },
        ]
      : []),
    {
      title: 'Rename step',
      icon: <Pencil1Icon />,
      onSelect: () => setIsRenameModalOpen(true),
    },
    {
      title: 'Delete step',
      type: 'danger',
      icon: <TrashIcon />,
      onSelect: () => setShowDeleteModal(true),
    },
  ];

  return (
    <>
      <ScreenHeader
        stepId={step._id}
        title={step.displayName}
        dueDate={step.dueDate}
        startDate={step.startDate}
        handleUpdateTimeFrame={(values) => step.update(values)}
        menu={<DropdownMenu items={items} size={'large'} />}
      />

      {isRenameModalOpen && (
        <RenameStepModal step={step} onCancel={() => setIsRenameModalOpen(false)} />
      )}

      {showLimitReachedModal && (
        <LimitReachedModal
          title="Upgrade your space to export your storyboard as a PDF"
          eventName="Export storyboard attempt"
          onCancel={() => setShowLimitReachedModal(false)}
        />
      )}

      {showDeleteModal && (
        <DeleteDialog
          onCancel={() => setShowDeleteModal(false)}
          onSubmit={confirmDeleteStep}
          title="Delete step"
          text={`Are you sure you want to delete this step?${
            step.tasks?.length ? ' All linked tasks will be gone as well.' : ''
          }`}
        />
      )}
    </>
  );
});
