import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import * as yup from 'yup';

import {
  BaseDialog,
  BaseDialogProps,
  DialogFooter,
  DialogHeader,
} from '../../../components/dialog';
import { ElevatedButton, TextButton } from '../../../components/buttons';
import { TextInput } from '../../../components/text-input/TextInput';
import { Label } from '../../../components/Input/styled-input';
import { SaveTaskDto, Task, TaskStatus } from '../../../app/entities/task';
import { StatusSelector } from '../../process/components/status-selector';
import { Project, ProjectPriority } from '../../../app/entities/project';
import { Step } from '../../../app/entities/step';
import { PrioritySelector } from '../../process/components/priority-selector';
import { DateSelector } from '../../../components/date-selector/date-selector';
import { TaskProjectSelector } from './task-project-selector';
import { TaskStepSelector } from './task-step-selector';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { breakPoint } from '../../../app/theme';
import { AssigneeSelector } from '../../../components/assignee-selector/assignee-selector';
import { TagsViewer } from '../../tags/components/tags-viewer';

type SaveTaskModalProps = BaseDialogProps & {
  defaultValues?: Partial<SaveTaskDto>;
  title: string;
  project?: Project;
  step?: Step;
  onClose: () => void;
};

export const SaveTaskModal: React.FC<SaveTaskModalProps> = ({
  onClose,
  title,
  defaultValues,
  project,
  step,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDateWarning, setShowDateWarning] = useState(false);

  const level = step ? 'step' : project ? 'project' : 'space';

  const createSchema = yup.object().shape({
    title: yup.string().max(128, 'Max 128 characters').required(),
    status: yup.mixed<TaskStatus>().oneOf(Object.values(TaskStatus), 'Invalid status').required(),
    description: yup.string().max(1000, 'Max 1000 characters').optional(),
    priority: yup
      .mixed<ProjectPriority>()
      .oneOf(
        [
          ProjectPriority.NO_PRIORITY,
          ProjectPriority.LOW,
          ProjectPriority.MEDIUM,
          ProjectPriority.HIGH,
          ProjectPriority.URGENT,
        ],
        'Invalid priority',
      )
      .optional(),
    dueDate: yup
      .string()
      .transform((_, val) => {
        // if user clicked on the "clear" button
        if (val === '') return;
        return val;
      })
      .optional(),
    stepId: yup
      .string()
      .test({
        name: 'validateStepId',
        exclusive: false,
        message: 'step is required field',
        test: function (value, context) {
          const projectId = context.parent.projectId;
          return value ? !!projectId : level !== 'step';
        },
      })
      .optional(),
    assigneeId: yup.string().optional(),
    projectId: yup
      .string()
      .test({
        name: 'validateProjectId',
        exclusive: false,
        message: 'project is required field',
        test: function (value) {
          return level !== 'space' ? !!value : true;
        },
      })
      .optional(),
    tagsIds: yup.array().of(yup.string()).optional(),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<SaveTaskDto>({
    mode: 'onSubmit',
    resolver: yupResolver(createSchema),
    defaultValues: {
      title: '',
      description: '',
      status: TaskStatus.Todo,
      priority: ProjectPriority.NO_PRIORITY,
      dueDate: undefined,
      projectId: project?._id,
      stepId: step?._id,
      assigneeId: undefined,
      tagsIds: [],
      ...defaultValues,
    },
  });

  const [watchedStepId, watchedDueDate, watchedProjectId] = watch([
    'stepId',
    'dueDate',
    'projectId',
  ]);

  useEffect(() => {
    if (!watchedStepId || !watchedDueDate) {
      setShowDateWarning(false);
      return;
    }

    const step = Step.getOne(watchedStepId);
    if (!step || !step.dueDate) {
      setShowDateWarning(false);
      return;
    }

    setShowDateWarning(new Date(watchedDueDate) > new Date(step.dueDate));
  }, [watchedStepId, watchedDueDate]);

  const onSubmit = async (input: SaveTaskDto) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    for (const key in input) {
      // @ts-ignore
      if (input[key] === '') delete input[key];
    }

    await Task.create(input);

    onClose();
    setIsLoading(false);
  };

  return (
    <BaseDialog onClose={onClose}>
      <DialogHeader title={title} onClose={onClose} />

      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <TextInput
              label="Task title"
              placeholder="eg. Write a compelling pitch for an indie film project"
              required
              {...register('title')}
              errorMessage={errors.title?.message}
            />
          </div>

          <Properties>Properties</Properties>

          <InputWrapper>
            <div>Status</div>

            <Controller
              name="status"
              control={control}
              render={({ field: { onChange, value } }) => (
                <StatusSelector
                  onChange={onChange}
                  statusMap={Task.StatusMapper}
                  status={value}
                  isViewOnly={false}
                  withTitle
                />
              )}
            />
          </InputWrapper>

          <InputWrapper>
            <div>Priority</div>

            <Controller
              name="priority"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectorWrapper data-borderless={value !== ProjectPriority.NO_PRIORITY}>
                  <PrioritySelector
                    onChange={onChange}
                    priority={value || ProjectPriority.NO_PRIORITY}
                    withTitle
                  />
                </SelectorWrapper>
              )}
            />
          </InputWrapper>

          <InputWrapper>
            <div>Assignee</div>

            <Controller
              name="assigneeId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectorWrapper data-borderless={!!value}>
                  <AssigneeSelector
                    project={project}
                    selectedMemberId={value}
                    onChange={onChange}
                  />
                </SelectorWrapper>
              )}
            />
          </InputWrapper>

          <InputWrapper>
            <div>Due date</div>

            <Controller
              name="dueDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DateInput>
                  <DateSelectorWrapper data-borderless={!!value}>
                    <DateSelector
                      placeholder="Set due date"
                      field="dueDate"
                      date={value}
                      onUpdate={onChange}
                    />
                  </DateSelectorWrapper>

                  {showDateWarning && (
                    <DateWarning>
                      <ExclamationTriangleIcon />
                      <span>Date is later than the step&apos;s due date</span>
                    </DateWarning>
                  )}
                </DateInput>
              )}
            />
          </InputWrapper>

          <InputWrapper>
            <div>Project</div>

            <Controller
              name="projectId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectorWrapper data-borderless={!!value} data-disabled={!!project}>
                  <TaskProjectSelector
                    onChange={(value) => {
                      onChange(value);
                      setValue('stepId', undefined);
                    }}
                    selectedProjectId={value}
                    isViewOnly={level !== 'space'}
                  />
                </SelectorWrapper>
              )}
            />
          </InputWrapper>

          <InputWrapper>
            <div>Step</div>

            <Controller
              name="stepId"
              control={control}
              render={({ field: { onChange, value } }) => {
                const selectedProject = watchedProjectId
                  ? Project.getOne(watchedProjectId)
                  : undefined;

                return (
                  <Tooltip
                    disabled={!!selectedProject}
                    text="Select project first"
                    position="bottom"
                  >
                    <SelectorWrapper
                      data-borderless={!!value}
                      data-disabled={!selectedProject || !!step}
                    >
                      <TaskStepSelector
                        project={selectedProject}
                        onChange={onChange}
                        selectedStepId={value}
                        isViewOnly={!selectedProject || level === 'step'}
                        withIcon
                      />
                    </SelectorWrapper>
                  </Tooltip>
                );
              }}
            />
          </InputWrapper>

          <InputWrapper>
            <div>Tags</div>

            <div>
              <Controller
                name="tagsIds"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TagsViewer
                    targetType="task"
                    overflow="wrap"
                    alignment="start"
                    onSelect={onChange}
                    tagsIds={value}
                  />
                )}
              />
            </div>
          </InputWrapper>
        </form>
      </Content>

      <DialogFooter
        actions={[
          <TextButton key={'cancel'} onClick={onClose} text={'Cancel'} />,
          <ElevatedButton
            key="save"
            text={'Create task'}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Content = styled.div`
  max-width: 47.5rem;
  padding: 1.6rem;
  flex: 1;
  overflow-y: scroll;

  ${Label} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media screen and (min-width: ${breakPoint.small}px) {
    width: 47.5rem;
    max-width: 100%;
  }
`;

const Properties = styled.h2`
  margin: 2.4rem 0 1.6rem 0;
  font-family: Inter, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: var(--color-grayscale-white);
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  margin-bottom: 1.6rem;

  & > div:first-child {
    flex-shrink: 0;
    width: 7.2rem;
    color: var(--color-grayscale-ghost);
    font-weight: 400;
    font-size: 1.4rem;
  }
`;

const DashedInput = css`
  display: flex;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 0.4rem;
  border: 0.1rem var(--color-texts-low-contrast) dashed;
  font-size: 1.4rem;
  height: 3.2rem;
  min-height: 3.2rem;
  font-weight: 400;
  overflow: hidden;

  &:hover {
    border: 0.1rem var(--color-texts-middle-contrast) dashed;
    background-color: var(--color-surfaces-bg-elevation-3);

    & div {
      color: var(--color-texts-low-contrast);
    }
  }

  &[data-disabled='true'] {
    background: transparent;
    border-color: var(--color-texts-low-contrast);
  }
`;

const SelectorWrapper = styled.div`
  ${DashedInput}

  & > div {
    height: 3.2rem;
    color: var(--color-texts-low-contrast);
  }

  & div {
    color: var(--color-texts-low-contrast);
    border-radius: 0.4rem;
  }

  &[data-borderless='true'] {
    border-color: transparent;

    &:hover:not([data-disabled='true']) {
      & > div {
        background: var(--color-surfaces-bg-elevation-3);
      }

      div {
        color: var(--color-texts-high-contrast);
        border-color: transparent;
      }
    }
  }
`;

export const DateSelectorWrapper = styled.div`
  ${DashedInput}

  width: fit-content !important;

  & > div {
    padding: 0.6rem 0.8rem;
    font-size: 1.4rem;
    color: var(--color-texts-low-contrast);

    &:hover {
      background-color: var(--color-surfaces-bg-elevation-3);
    }
  }

  &[data-borderless='true'] {
    border-color: transparent;
    & > div {
      color: var(--color-texts-high-contrast);
    }
  }
`;

const DateWarning = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: var(--color-grayscale-ghost);
  font-size: 1.2rem;
  font-weight: 400;
  margin-left: 0.8rem;
  margin-top: 0.4rem;

  & > svg {
    color: var(--color-secondary-saffron);
  }
`;

const DateInput = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
  }
`;
