import React, { useState } from 'react';
import { Edit, CirclePlus } from '@producer-io/ui-kit';
import { observer } from 'mobx-react-lite';
import { TrashIcon } from '@radix-ui/react-icons';

import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { AssignContactsModal } from '../../contacts/components/assign-contacts.modal';
import { MembersList } from '../../../routes/project/components/shootingday/members-list';
import { RenameDialog } from '../../../components/dialogs/rename.dialog';
import { DeleteDialog } from 'components/dialogs/DeleteDialog';
import { catchError } from '../../../core/catch-error';
import { CollapsibleItem } from '../../../components/collapsible-item/collapsible-item';
import { DropdownTrigger } from '../../projects/components/styled-project-components';
import { EmptyTablePlaceholder } from '../../../components/table/empty-table-placeholder';
import { ConditionalWrapper } from '../../../components/conditional-wrapper/conditional-wrapper';
import { useResponsive } from '../../../hooks/useResponsive';

import type { Team } from '../../../app/entities/team';
import type { TeamContact } from '../models/team';

type DepartmentItemProps = {
  spaceId: string;
  projectId: string;
  department: { _id: string; name: string; contacts: TeamContact[] };
  team: Team;
};

export const DepartmentItem: React.FC<DepartmentItemProps> = observer(
  ({ department, team, spaceId }) => {
    const { isDesktop } = useResponsive();

    const [showAddContactModal, setShowAddContactModal] = useState(false);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const handleDeleteDepartment = async () => {
      await team.deleteDepartment(department._id);
    };

    const handleDeleteCrewMember = async (contactId: string) => {
      await team.deleteContact(contactId);
    };

    const handleOnUpdate = async (contactId: string, values: Partial<TeamContact>) => {
      await team.updateContact(contactId, values);
    };

    const handleAssignTeamContact = async (contactId: string, role?: string) => {
      await team.addContact({
        contactId,
        departmentId: department?._id,
        role: role || '',
      });
    };

    const handleRenameTeam = async (name: string) => {
      try {
        await team.updateDepartment(department._id, { name });
      } catch (e) {
        catchError(e);
      }
    };

    const menuItems = [
      {
        title: 'Rename',
        icon: <Edit />,
        onSelect: () => setIsRenameModalOpen(true),
      },
      {
        title: 'Add crew member',
        icon: <CirclePlus />,
        onSelect: () => setShowAddContactModal(true),
      },
      {
        title: 'Delete',
        icon: <TrashIcon />,
        type: 'danger' as 'danger',
        onSelect: () => setIsDeleteModalOpen(true),
      },
    ];

    return (
      <>
        <AssignContactsModal
          assignedContacts={team.contacts}
          addedContacts={department.contacts}
          onAdd={handleAssignTeamContact}
          onDelete={handleDeleteCrewMember}
          onCancel={() => setShowAddContactModal(false)}
          spaceId={spaceId}
          title="Add Crew Member"
          subtitle="Added members"
          isOpen={showAddContactModal}
        />

        {isRenameModalOpen && (
          <RenameDialog
            defaultName={department.name}
            onSubmit={handleRenameTeam}
            onCancel={() => setIsRenameModalOpen(false)}
            title="Rename department"
            label="Department name"
          />
        )}

        {isDeleteModalOpen && (
          <DeleteDialog
            onSubmit={handleDeleteDepartment}
            onCancel={() => setIsDeleteModalOpen(false)}
            title="Delete department"
            text={`Are you sure you want to delete ${department.name} department?`}
          />
        )}

        <ConditionalWrapper
          condition={isDesktop}
          wrapper={(children) => (
            <CollapsibleItem
              title={department.name}
              actions={
                <DropdownMenu
                  trigger={<DropdownTrigger data-dark="true">⋮</DropdownTrigger>}
                  items={menuItems}
                />
              }
              defaultOpen
            >
              {children}
            </CollapsibleItem>
          )}
        >
          <MembersList
            title={department.name}
            actions={
              <DropdownMenu
                trigger={<DropdownTrigger data-dark="true">⋮</DropdownTrigger>}
                items={menuItems}
              />
            }
            onUpdate={handleOnUpdate}
            contacts={department.contacts}
            onDelete={handleDeleteCrewMember}
            placeholder={
              !department?.contacts?.length && (
                <EmptyTablePlaceholder
                  description="Department is empty right now."
                  action={{
                    text: 'Add crew members',
                    onClick: () => setShowAddContactModal(true),
                  }}
                />
              )
            }
          />
        </ConditionalWrapper>
      </>
    );
  },
);
